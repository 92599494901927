<template>
  <b-container
    fluid
    id="profile-container"
    class=" d-flex flex-column bg-secondary pl-0 gx-0">
    <div class="section-container">
      <div class="section-header-container dark-bg d-flex flex-column justify-content-center align-items-center">
        <h2 class="bg-shadow outline">Profile</h2>
        <div class="title">
          <h3 class="bright background"><span>Profile</span></h3>
        </div>
      </div>
      <b-container
        fluid
        id="profile"
        class="section-content-container d-flex space-between pl-0 gx-0"
        v-observe-visibility="visibilityChanged">
        <div
        class='profile-pic'>
          <b-img :src="require('@/assets/images/ProfilePicture4.png')" fluid alt="Responsive image"></b-img>
        </div>
        <div
          class='profile-text'>
          <div class="sm-title">
            <h4>About Me</h4>
          </div>
          <div class="sm-body">
            <h6>Hello, my name is <span>Justin</span> and I am a <span>Software Developer</span></h6>
            <p>I have {{techList()[0].techExperience}} of experience as a web developer and software engineer, having worked in full-time roles and contracts. I am self-motivated and passionate about coding, which has led to a remarkable output characterized by a harmonious blend of quality and quantity.
            <br>
            <br>
            I am a skilled professional with unique creative and analytical abilities. Crafting websites, web apps, and SaaS apps is not just a job; it's a means of self-expression that brings me joy and satisfaction. I am passionate about pushing the boundaries of what's possible in web and software engineering, and I thrive on challenges. I have experience in a wide range of industries, such as fintech, healthcare, e-commerce, online gaming, insurance, and auditing.
            <br>
            <br>
            I am skilled in the entire development lifecycle, from planning to database design. My proficiency spans both front-end and back-end development with a focus on PHP. I am experienced in server setup and deployment, collaborative peer reviews, API integrations, OAuth, and payment gateway integrations. I also have experience in team leadership and fostering a collaborative and growth-oriented environment for junior team members.</p>
          </div>
          <div class="sm-title">
            <h4>Skills</h4>
          </div>
          <div class="sm-body">
            <span
              v-for="(skill, skillIndex) in skills"
              :key="skillIndex"
              class="tech-badge badge rounded-pill animate__animated animate__fadeIn"
              :class="{'featured-tech' : cycleFeaturedSkills && featuredSkill === skillIndex}"
              @mouseenter="stopCyclingFeaturedSkills(skillIndex)"
              @mouseleave="startCyclingFeaturedSkills()"
              @click="scrollToSection('#experience-container')">
              <!-- :class="{'featured-tech' : featuredSkills.includes(skillIndex)}" -->
              {{skill}}
            </span>
          </div>
          <div class="sm-title">
            <h4>Technologies</h4>
          </div>
          <div class="sm-body">
            <span
              v-for="(tech, techIndex) in techList()"
              :key="techIndex"
              class="tech-badge badge rounded-pill animate__animated animate__fadeIn"
              :class="{'featured-tech' : cycleFeaturedTech && featuredTech === techIndex}"
              @mouseenter="stopCyclingFeaturedTech(techIndex)"
              @mouseleave="startCyclingFeaturedTech()"
              @click="scrollToSection('#technologies-container')">
              {{tech.name}}
            </span>
          </div>

        </div>

        <!-- Content here -->
      </b-container>
    </div><!-- / section-container -->
  </b-container>
</template>

<script>
import HelperMixin from '@/services/HelperMixin'
import DataMixin from '@/services/DataMixin'

export default {
  name: 'AboutMe',

  mixins: [DataMixin, HelperMixin],

  data() {
    return {
      featuredTech: 0,
      featuredTechs: [],
      cyclingFeaturedTech: false,
      cycleFeaturedTech: null,
      featuredSkill: 0,
      featuredSkills: [],
      cycleFeaturedSkills: null,
    }
  },

  mounted() {
    // this.chooseFeaturedTechs()
    this.startCyclingFeaturedTech()
    // this.chooseFeaturedSkills()
    this.startCyclingFeaturedSkills()
  },

  methods: {
    startCyclingFeaturedTech() {
      if (!this.cycleFeaturedTech) {
      // this.cyclingFeaturedTech = true
        this.cycleFeaturedTech = setInterval(() => {
          // this.chooseFeaturedTechs()
          this.chooseFeaturedTech()
        }, 2000)
      }
    },
    stopCyclingFeaturedTech(techIndex) {
      // this.featuredTech = []
      clearInterval(this.cycleFeaturedTech)
      this.cycleFeaturedTech = null
      this.featuredTech = techIndex
    },
    chooseFeaturedTech() {
      const max = this.techList().length - 1
      this.featuredTech = this.featuredTech < max ? this.featuredTech + 1 : 0
    },
    chooseFeaturedTechs() {
      const max = this.techList().length - 1
      const numberOfFeatured = Math.round(this.techList().length / 5)
      this.featuredTechs = []
      while (this.featuredTechs.length < numberOfFeatured) {
        const randomNumber = Math.floor(Math.random() * (max + 1))
        if (!this.featuredTechs.includes(randomNumber)) {
          this.featuredTechs.push(randomNumber)
        }
      }
    },
    startCyclingFeaturedSkills() {
      if (!this.cycleFeaturedSkills) {
        this.cycleFeaturedSkills = setInterval(() => {
          // this.chooseFeaturedSkills()
          this.chooseFeaturedSkill()
        }, 2000)
      }
    },
    stopCyclingFeaturedSkills(skillIndex) {
      // this.featuredSkills = []
      clearInterval(this.cycleFeaturedSkills)
      this.featuredSkill = skillIndex
      this.cycleFeaturedSkills = null
    },
    chooseFeaturedSkill() {
      const max = this.skills.length - 1
      this.featuredSkill = this.featuredSkill < max ? this.featuredSkill + 1 : 0
    },
    chooseFeaturedSkills() {
      const max = this.skills.length - 1
      const numberOfFeatured = Math.round(this.skills.length / 5)
      this.featuredSkills = []
      while (this.featuredSkills.length < numberOfFeatured) {
        const randomNumber = Math.floor(Math.random() * (max + 1))
        if (!this.featuredSkills.includes(randomNumber)) {
          this.featuredSkills.push(randomNumber)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
#profile-container {
  min-height: calc(100vh);
  position: relative;
  z-index: 3;
  padding-left: 0;

  #profile {
    padding-left: 0;
    display: flex;
    justify-content: space-between;

    .profile-pic {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // width: 50%;
      img {
        max-height: 900px;
      }
    }
    .profile-text {
      width: 50%;
      padding-right: 2rem;
      .sm-title {
        border-left: #f71735 2px solid;
        padding-left: 1rem;
        color: white;
      }
      .sm-body {
        padding-left: 1rem;
        h6 {
          color: white;
        }
        .tech-badge {
          border: 1px solid rgba(255, 255, 255, 0.6);
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 0.5rem;
          margin-right: 0.5rem;
          &:hover, &.featured-tech {
            border-color: white;
            color: white;
          }
        }
      }

    }
    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;

      .profile-text {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 100%;
        padding-right: 2rem;
      }
    }
  }

}

.section-content-container {
  .section-text-container {
  }
}
</style>
