<template>
  <div
    id="technologies-container"
    class="container-fluid d-flex flex-column bg-secondary">
    <div class="section-container">
      <div class="section-header-container dark-bg d-flex flex-column justify-content-center align-items-center">
        <h2 class="bg-shadow outline">Tech</h2>
        <div class="title">
          <h3 class="bright background"><span>Tech</span></h3>
        </div>
      </div>
      <b-container
        id="technologies"
        class="section-content-container"
        v-observe-visibility="visibilityChanged">
        <b-row>
          <b-col
            v-for="(tech, techIndex) in techList()"
            :key="techIndex"
            cols="12"
            md="3"
            class="tech-block section-text-container dark-bg">
            <b-card no-body style="max-width: 540px;">
              <div class="hexagon">
                <b-img
                  v-if="tech.logo"
                  class="tech-logo"
                  :src="tech.logo"
                  :alt="tech.name + ' image'"></b-img>
              </div>
              <b-row no-gutters class="tech-info">
                <b-col>
                  <b-card-body >
                    <b-card-title>
                      <a :href="tech.link" target="_blank">
                        {{tech.name}}
                      </a>
                    </b-card-title>
                    <b-card-text>
                      {{tech.level}}
                    </b-card-text>
                    <b-card-text>
                      {{tech.techExperience}} || <span :class="{ 'current': tech.lastUsed === 'current' }">{{tech.lastUsed}}</span>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col><!-- / section-text-container -->
        </b-row>
        <!-- Content here -->
      </b-container>
    </div><!-- / section-container -->
  </div>
</template>

<script>
import HelperMixin from '@/services/HelperMixin'
import DataMixin from '@/services/DataMixin'

export default {
  name: 'MyTech',

  mixins: [DataMixin, HelperMixin],
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
#technologies-container {
  min-height: calc(100vh);
  position: relative;
  z-index: 3;
  padding-bottom: 3rem;

  .tech-block {
    margin-bottom: 0;
    margin-top: 4rem;

    .card {
      background: rgba(255, 255, 255, 0.05);
      text-align: center;

      .hexagon {
        text-align: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin: 0 auto 30px;
        top: -30px;
        transition: ease all 0.35s;
        // transition: ease all 0.35s;

        background-color: #f71735;
        border-radius: 10px;
        width: 100px;
        height: 63px;
        padding: 0 1rem;

        &:before, &:after {
        //
          content: "";
          border: inherit;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #f71735;
          height: 100%;
          width: 100%;
          border-radius: inherit;
          pointer-events: none;
        }

        &:before {
          -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
        }
        &:after {
          -webkit-transform: rotate(-60deg);
          transform: rotate(-60deg);
        }
        .tech-logo {
          position: relative;
          z-index: 4;
          max-height: 100%;
          max-width: 100%;
          transition: ease all 0.5s;
          border-radius: 5px;
        }
      }

      &:hover {
        .hexagon {
          top: -20px;

          .tech-logo {
            max-width: 120%;
            max-height: 120%;
            //   max-width: 110%;
          //   max-height: 110%;
          //   transition: ease max-height 2s;
          //   transition: ease max-width 2s;
          //   transition: ease height 2s;
          //   transition: ease width 2s;
          }
        }
      }

      .tech-info {
        position: relative;
        top: -1rem;

        .card-body {
          max-height: 257px;
          position: relative;
          overflow: hidden;
          padding-top: 0;
          padding-bottom: 0;
          .card-title {
            margin-bottom: 0;
            a {
              color: white;
              text-decoration: none;
            }
          }

          .card-subtitle {
            color: rgba(255, 255, 255, 0.8) !important;
          }

          .card-text {
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 0;

            &.company-name {
              font-size: 0.75rem;
            }
            .current {
              color: white;
            }
          }
        }
      }

    // .card-body::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 50px; /* Height of the white faded overlay */
    //   background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.05)); /* White faded overlay */
    // }

    }

  }

}
</style>
