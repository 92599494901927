<template>
  <div>
    <b-container
      fluid
      class="d-flex flex-column bg-secondary pl-0 gx-0 py-1">
      <div class="section-container">
        <div class="section-content-container dark-bg d-flex flex-column justify-content-center align-items-center">
          <div class="contact-down-icon-wrapper d-flex flex-row">
            <div class="contact-down-icon animate__animated animate__pulse animate__slow animate__infinite">
              <a
                @click="scrollToSection('#footer')">
                <font-awesome-icon :icon="['fas',  'arrow-down']" />
              </a>
            </div>
            <div class="contact-down-text d-inline-block va-middle ml-10 fs-2 fw-light opc-75 bg-shadow outline">Contact Me</div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import EventBus from '@/services/event-bus'
import HelperMixin from '@/services/HelperMixin'

export default {
  name: 'ContactLink',

  mixins: [HelperMixin],

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
.contact-down-icon-wrapper {
  display: flex;
  align-items: center;
  color: $color-white-main;
  .contact-down-icon {
    border: 2px solid $color-white-main;
    opacity: 0.09;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    cursor: pointer;
      &:hover {
        color: $color-white-main;
        // border-color: $color-white-main;
        opacity: 0.5;
      }
  }
  .contact-down-text {
    opacity: 0.09;
  }
}
</style>
