<template>
  <div
    id="portfolio-container"
    class="container-fluid d-flex flex-column bg-secondary">
    <div class="section-container">
      <div class="section-header-container dark-bg d-flex flex-column justify-content-center align-items-center">
        <h2 class="bg-shadow outline">Portfolio</h2>
        <div class="title">
          <h3 class="bright background"><span>Portfolio</span></h3>
        </div>
      </div>
      <b-container
        id="portfolio"
        class="section-content-container"
        v-observe-visibility="visibilityChanged">
        <b-row>
          <b-col
            v-for="(project, projectIndex) in projects"
            :key="projectIndex"
            cols="12"
            md="6"
            class="section-text-container dark-bg mb-5">
            <b-card
              no-body
              @click="showPortfolioModal(project)"
              class="overflow-hidden"
              style="max-width: 540px;">
              <b-row no-gutters>
                <b-col
                  md="6"
                  class="card-image-wrapper">
                  <b-card-img :src="project.thumb" alt="Image" class="rounded-0"></b-card-img>
                </b-col>
                <b-col md="6">
                  <b-card-body :title="project.name">
                    <b-card-text>
                      {{project.description}}
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col><!-- / section-text-container -->
        </b-row>
        <!-- Content here -->
      </b-container>
    </div><!-- / section-container -->
    <b-modal
      id="portfolio-modal"
      :class="{'full-screen' : toggleFullScreen}"
      hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5>{{loadedProject.name}}</h5>
        <b-button
          pill
          variant="outline-warning"
          size="sm"
          @click="close()">
          <font-awesome-icon :icon="['fas',  'xmark']" />
        </b-button>
      </template>

      <div class="project-modal-content-container flex">
        <div
          class="project-details animate__animated"
          :class="{
            'animate__fadeOutRight full-screen': toggleFullScreen,
            'animate__fadeInRight': !toggleFullScreen
          }">
          <h3>Project Details</h3>
          <p>{{loadedProject.description}}</p>
          <div class="detail-container">
            <span class="label">Project: </span>{{loadedProject.name}}
          </div>
          <div v-if="loadedProject.company" class="detail-container">
            <span class="label">While at: </span>{{companyBySlug(loadedProject.company).company}}
          </div>
          <div class="detail-container">
            <span class="label">Tech: </span>
            <span
              v-for="(tech, techIndex) in loadedProject.tech"
              :key="techIndex"
              class="tech-badge badge rounded-pill"
              :class="{'featured-tech' : cycleFeaturedTech && featuredTech === techIndex}"
              @mouseenter="stopCyclingFeaturedTech(techIndex)"
              @mouseleave="startCyclingFeaturedTech()"
            >
              {{techBySlug(tech)?.name}}
            </span>
          </div>
          <div
            v-if="loadedProject.features"
            class="detail-container"
            >
            <span class="label">Features: </span>
            <span
            v-for="(feature, featureIndex) in loadedProject.features"
            :key="featureIndex"
            class="tech-badge badge rounded-pill"
              :class="{'featured-tech' : cycleFeaturedSkills && featuredSkill === featureIndex}"
              @mouseenter="stopCyclingFeaturedSkills(featureIndex)"
              @mouseleave="startCyclingFeaturedSkills()"
            >
              {{feature}}
            </span>
          </div>
          <div
            v-if="loadedProject.keyContributions"
            class="detail-container"
            >
            <span class="label">Key Contributions: </span>
            <p v-html="loadedProject.keyContributions"></p>
          </div>
          <b-button
            pill
            variant="outline-warning"
            :href="loadedProject.url"
            target="_blank">
            <font-awesome-icon :icon="['fab',  'safari']" /> website
          </b-button>
        </div>
        <div
          :class="{'full-screen' : toggleFullScreen}"
          class="project-visuals"
          >
          <b-button
            pill
            variant="warning"
            class="animate__animated animate__pulse animate__slow animate__infinite"
            @click="toggleFullScreen = !                toggleFullScreen">
            <font-awesome-icon :icon="['fas',  toggleFullScreen ? 'arrow-right' : 'arrow-left']" />
          </b-button>
            <img
              v-if="loadedProject.images?.length === 1"
              :src="currentImage"
              v-on:mouseover="stopRotation"
              v-on:mouseout="startRotation"
            />
            <transition-group
              v-else
              name='fade'
              tag='div'>
              <div
                v-for="number in [currentNumber]"
                :key='number'
                >
                <img
                  :src="currentImage"
                  v-on:mouseover="stopRotation"
                  v-on:mouseout="startRotation"
                  />
              </div>
            </transition-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import HelperMixin from '@/services/HelperMixin'
import DataMixin from '@/services/DataMixin'

export default {
  name: 'MyWork',

  mixins: [
    DataMixin,
    HelperMixin,
  ],

  data() {
    return {
      toggleFullScreen: false,
      loadedProject: null,

      defaultLoadedProject: {
        name: null,
        url: null,
        tech: [],
        features: [],
        slug: null,
        company: null,
        industry: null,
        thumb: null,
        images: [],
        /* eslint-disable max-len */
        description: null,
        /* eslint-disable max-len */
        keyContributions: null,
      },
      projectDetailsHeight: 0,
      currentNumber: 0,
      timer: null,

      featuredTech: 0,
      cycleFeaturedTech: null,
      featuredSkill: 0,
      cycleFeaturedSkills: null,
    }
  },

  created() {
    this.loadedProject = this.defaultLoadedProject
  },

  computed: {
    currentImage() {
      return this.loadedProject?.images[Math.abs(this.currentNumber) % this.loadedProject?.images.length];
    },
  },

  methods: {
    showPortfolioModal(project) {
      this.toggleFullScreen = false
      this.loadedProject = project
      this.$bvModal.show('portfolio-modal')
      this.startRotation()

      if (this.loadedProject?.tech?.length) {
        this.startCyclingFeaturedTech()
      }
      if (this.loadedProject?.features) {
        this.startCyclingFeaturedSkills()
      }
    },
    startRotation() {
      this.timer = setInterval(this.next, 5000);
    },

    stopRotation() {
      clearTimeout(this.timer);
      this.timer = null;
    },

    next() {
      this.currentNumber += 1
    },
    prev() {
      this.currentNumber -= 1
    },

    startCyclingFeaturedTech() {
      if (!this.cycleFeaturedTech) {
        this.cycleFeaturedTech = setInterval(() => {
          this.chooseFeaturedTech()
        }, 2000)
      }
    },
    stopCyclingFeaturedTech(techIndex) {
      clearInterval(this.cycleFeaturedTech)
      this.cycleFeaturedTech = null
      this.featuredTech = techIndex
    },
    chooseFeaturedTech() {
      const max = this.loadedProject?.tech?.length - 1
      this.featuredTech = this.featuredTech < max ? this.featuredTech + 1 : 0
    },
    startCyclingFeaturedSkills() {
      if (!this.cycleFeaturedSkills) {
        this.cycleFeaturedSkills = setInterval(() => {
          this.chooseFeaturedSkill()
        }, 2000)
      }
    },
    stopCyclingFeaturedSkills(featureIndex) {
      clearInterval(this.cycleFeaturedSkills)
      this.featuredSkill = featureIndex
      this.cycleFeaturedSkills = null
    },
    chooseFeaturedSkill() {
      const max = this.loadedProject?.features?.length - 1
      this.featuredSkill = this.featuredSkill < max ? this.featuredSkill + 1 : 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
#portfolio-container {
  min-height: calc(100vh);

  .card {
    background: rgba(15, 38, 52, 1);
    cursor: pointer;
    border: transparent 2px solid;

    .card-image-wrapper {
      overflow: hidden;
      max-height: 257px;
    }

  .card-body {
    max-height: 257px;
    position: relative;
    overflow: hidden;
  }
  .card-body::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Height of the white faded overlay */
    background: linear-gradient(to bottom, rgba(15, 38, 52, 0), rgba(15, 38, 52,  1)); /* faded overlay */
  }

    .card-title {
      color: white;
    }

    .card-subtitle {
      color: rgba(255, 255, 255, 0.8) !important;
    }

    .card-text {
      color: rgba(255, 255, 255, 0.6);

      &.company-name {
        font-size: 0.75rem;
      }
    }
    &:hover {
      border: #f71735 2px solid;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/base/_variables.scss';
#portfolio-modal {
  .modal-dialog {
    max-width: unset;
    width: 98vw;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .modal-content {
      background-color: #191b23;
      color: #d6d7d8;
      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h5 {
          margin-bottom: 0;
        }
      }
      .modal-body {
        .project-modal-content-container {
          display: flex;
          justify-content: space-between;

          .project-details {
            width: 50%;
            padding: 1rem;
            transition: all 1s ease;

            .detail-container {
              margin-bottom: 1rem;
              .label {
                color:#f3dc8e;
                margin-right: 0.75rem;
              }
              .tech-badge {
                border: 1px solid rgba(255, 255, 255, 0.6);
                color: rgba(255, 255, 255, 0.6);
                margin-bottom: 0.5rem;
                margin-right: 0.5rem;
                &:hover, &.featured-tech {
                  border-color: white;
                  color: white;
                }
              }
            }

            &.full-screen {
              width: 0%;
              padding: 0;
            }
          }
          .project-visuals {
            position: relative;
            width: 50%;
            padding:0 1rem;
            transition: width 2s ease;

            button {
              position: absolute;
              top: 1rem;
              left: 2rem;
              z-index: 9999;
            }
            .fade-enter-active, .fade-leave-active {
              transition: all 0.8s ease;
              overflow: hidden;
              visibility: visible;
              opacity: 1;
              position: absolute;
            }
            .fade-enter, .fade-leave-to {
              opacity: 0;
              visibility: hidden;
            }
            img {
              width: 100%;
            }

            &.full-screen {
              width: 100%;
              max-height: unset;

              img {
                width: 100%;
              }
            }
          }
          @media only screen and (max-width: 767px) {
            display: block;
            .project-details {
              width: 100%;
              padding: 0rem;
              margin-bottom: 2rem;
            }
            .project-visuals {
              position: relative;
              width: 100%;
              padding:0 0rem;
            }
            button {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
