import Vue from 'vue'
// import 'animate.css'
import { BootstrapVue } from 'bootstrap-vue'
import VueObserveVisibility from 'vue-observe-visibility'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueParallaxJs from 'vue-parallax-js'
import VideoBackground from 'vue-responsive-video-background-player'
// import VueMarqueeSlider from 'vue-marquee-slider'
// eslint-disable-next-line
// import PrimeVue from 'primevue/config'
// eslint-disable-next-line
// import Carousel from 'primevue/carousel'
// Vue.use(VueMarqueeSlider)
// eslint-disable-next-line
import Flicking from "@egjs/vue-flicking"
// eslint-disable-next-line
import "@egjs/vue-flicking/dist/flicking.css"
// Or, if you have to support IE9
// eslint-disable-next-line
import "@egjs/vue-flicking/dist/flicking-inline.css"

import {
  faSafari,
} from '@fortawesome/free-brands-svg-icons'
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import App from './App'

// eslint-disable-next-line
// import { Fade, Parallax, AutoPlay } from "@egjs/flicking-plugins";

// flicking.addPlugins(new AutoPlay());

// eslint-disable-next-line
import '@/assets/scss/main.scss'

library.add(
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faSafari,
  faXmark,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.use(VueObserveVisibility)

Vue.use(VueParallaxJs)

Vue.component('video-background', VideoBackground)

// Vue.use(VueMarqueeSlider)

// Vue.use(PrimeVue);
// eslint-disable-next-line
// Vue.component('Carousel', Carousel);
// Vue.use(Carousel);

Vue.use(Flicking)

new Vue({
  render: (h) => h(App),
}).$mount('#app')
