<template>
  <div
    id="experience-container"
    class="container-fluid d-flex flex-column bg-secondary">
    <div class="container section-container">
      <div class="section-header-container dark-bg d-flex flex-column justify-content-center align-items-center">
        <h2 class="bg-shadow outline">Experience</h2>
        <div class="title">
          <h3 class="bright background"><span>Experience</span></h3>
        </div>
      </div>
      <b-container
        id="experience"
        class="section-content-container"
        v-observe-visibility="visibilityChanged">
        <b-row
          class="tech-row"
          v-for="(experience, experienceIndex) in experience"
          :key="experienceIndex">
          <b-col
            class="section-date-container d-flex flex-column justify-content-center dark-bg mb-3"
            md="2">
            <div class="date-badge">
              <div class="date-badge-text">
                {{experience.startDate}} - {{experience.endDate}}</div>
              </div>
          </b-col>
          <b-col
            class="section-text-container dark-bg mb-md-5 mb-3">
            <b-card no-body class="resume-box overflow-hidden">
              <b-row>

                <b-col>
                  <b-card-body
                    :title="experience.title"
                    :sub-title="experience.company+', '+experience.industry">
                  <b-card-text>
                    {{experience.description}}
                  </b-card-text>
                </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col><!-- / section-text-container -->
        </b-row>
        <!-- Content here -->
      </b-container>
    </div><!-- / section-container -->
  </div>
</template>

<script>
import HelperMixin from '@/services/HelperMixin'
import DataMixin from '@/services/DataMixin'

export default {
  name: 'MyExperience',

  mixins: [DataMixin, HelperMixin],

  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
#portfolio-container {
  min-height: calc(100vh);
}

.section-content-container {
  border-left: #f71735 2px solid;

  .tech-row {
    padding-left: 0;
    .section-date-container {
      padding-left: 0;
      .date-badge {
        position: relative;
        z-index: 2;

        &:before {
          border-top: 2px solid #f71735;
          content: "";
          margin: 0 auto;
          position: absolute;
          top: 50%;
          right: 0;
          bottom: 0;
          width: 100%;
          z-index: -1;
        }

        .date-badge-text {
          background: #011627;
          width: fit-content;
          padding: 0 5px;
          margin-left: 10px;
          color: white;
        }
      }
      @media only screen and (max-width: 767px) {

      }
    }
    .resume-box {
      background: rgba(255, 255, 255, 0.05);
      border: transparent 2px solid;

      .badge  {
        font-weight: unset;
        margin-top: 1rem;
        margin-left: 1rem;
        background-color: rgba(255, 255, 255, 0.1);
      }

      .card-title {
        color: white;
      }

      .card-subtitle {
        color: rgba(255, 255, 255, 0.8) !important;
      }

      .card-text {
        color: rgba(255, 255, 255, 0.6);

        &.company-name {
          font-size: 0.75rem;
        }
      }
    }

    &:hover {
      .resume-box {
        border: #f71735 2px solid;
      }
    }
  }

}
</style>
