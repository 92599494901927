<template>
  <div>
    <b-navbar
      fixed="top"
      :class="{'scrolling' : scrolling}"
      >
      <b-collapse id="nav-collapse" is-nav  class="flex">
        <b-navbar-nav
        :class="[scrollDirection]">
          <b-nav-item
            v-for="(leftItem, leftItemIndex) in navItems.slice(0, navItems.length / 2)"
            :key="leftItemIndex"
            @click="scrollToSection(leftItem.element)"
            :active="currentlyVisible === leftItem.visible"
            :link-classes="{'previous' : previouslyVisible === leftItem.visible}">
            {{leftItem.title}}
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-brand
          class="brand-logo-container"
          @click="scrollToSection('#header')">
          <div class="outline-double" title="JUSTIN">JUSTIN</div>
          <div class="fw-normal bright">SMITH</div>
        </b-navbar-brand>
        <b-navbar-nav
          :class="[scrollDirection]">
          <b-nav-item
            v-for="(rightItem, rightItemIndex) in navItems.slice(-(navItems.length / 2))"
            :key="rightItemIndex"
            @click="scrollToSection(rightItem.element)"
            :active="currentlyVisible === rightItem.visible"
            :link-classes="{'previous' : previouslyVisible === rightItem.visible}">
            {{rightItem.title}}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import EventBus from '@/services/event-bus'
import HelperMixin from '@/services/HelperMixin'

export default {
  name: 'NavBar',

  mixins: [HelperMixin],

  data() {
    return {
      currentlyVisible: 'header',
      previouslyVisible: 'header',
      scrollDirection: null,
      scrolling: false,
    };
  },

  mounted() {
    EventBus.$on('currentlyVisibleElement', (element) => this.activateChange(element));
    EventBus.$on('noLongerVisibleElement', (element) => this.deActivateChange(element));

    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    window.addEventListener(
      'scroll',
      function handleScroll() {
        const { scrollTop } = document.documentElement;
        const scrollTopPosition = window.pageYOffset || scrollTop;

        if (scrollTop > 0 && !this.scrolling) {
          this.scrolling = true
        } else if (scrollTop === 0 && this.scrolling) {
          this.scrolling = false
        }

        if (scrollTopPosition > lastScrollTop && this.scrollDirection !== 'down') {
          this.scrollDirection = 'down'
        } else if (scrollTopPosition < lastScrollTop && this.scrollDirection !== 'up') {
          this.scrollDirection = 'up'
        }
        lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
      }.bind(this),
      false,
    );
  },

  methods: {
    activateChange(newElement) {
      this.previouslyVisible = this.currentlyVisible
      this.currentlyVisible = newElement
    },
    deActivateChange(newElement) {
      if (this.scrollDirection === 'up') {
        this.previouslyVisible = newElement
        const currentIndex = this.navItems.findIndex((item) => item.visible === newElement)
        this.currentlyVisible = currentIndex > 0 ? this.navItems[currentIndex - 1].visible : 'header'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
.brand-logo-container {
  border: $color-white-main 0.106rem solid;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color-white-main;
  margin-top: 5px;

  .outline-single,
  .outline-double {
    position: relative;
    background: transparent;
    z-index: 0;
    color: #181b23;
    font-size: 2rem;
    padding-top: 0.37rem;
    line-height: 1;
    -webkit-text-stroke-width: 1px;
  }
  /* add a single stroke */
  .outline-single:before,
  .outline-double:before {
    content: attr(title);
    position: absolute;
    background: transparent;
    -webkit-text-stroke: 0.2rem $color-white-main;
    color: transparent !important;
    left: 0;
    z-index: -1;
  }
  /* add a double stroke */
  .outline-double:after {
    content: attr(title);
    position: absolute;
    -webkit-text-stroke: 0.40em #181b23;
    color: transparent !important;
    left: 0;
    z-index: -2;
  }
  .bright {
    position: relative;
    z-index: 1;
    font-size: 0.74rem;
    line-height: 1;
    margin-bottom: 0;
    color: $color-white-main;
  }
}
@media only screen and (max-width: 767px) {
  .brand-logo-container {
    display: none;
  }
}
</style>
