import EventBus from '@/services/event-bus'
import { computed } from 'vue'

export default {
  data() {
    return {
      navItems: [
        {
          title: 'PROFILE',
          element: '#profile-container',
          visible: 'profile',
        },
        {
          title: 'EXPERIENCE',
          element: '#experience-container',
          visible: 'experience',
        },
        {
          title: 'PORTFOLIO',
          element: '#portfolio-container',
          visible: 'portfolio',
        },
        {
          title: 'TECHNOLOGIES',
          element: '#technologies-container',
          visible: 'technologies',
        },
        // {
        //   title: 'SKILLS',
        //   element: '#skills-container',
        //   visible: 'skills',
        // },
        // {
        //   title: 'REFERENCES',
        //   element: '#references-container',
        //   visible: 'references',
        // },
        // {
        //   title: 'EDUCATION',
        //   element: '#education-container',
        //   visible: 'education',
        // },
        // {
        //   title: 'CONTACT',
        //   element: '#contact-container',
        //   visible: 'contact',
        // },
      ],
    }
  },

  methods: {
    scrollToSection(section) {
      console.log('scrolling to')
      console.log(section)
      const elementToScrollTo = document.querySelector(section)
      window.scrollTo({
        top: Math.round(elementToScrollTo.getBoundingClientRect().top + document.documentElement.scrollTop - 91),
        behavior: 'smooth',
      })
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        EventBus.$emit('currentlyVisibleElement', entry.target.id);
      } else {
        EventBus.$emit('noLongerVisibleElement', entry.target.id);
      }
    },
  },
}
