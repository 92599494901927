import moment from 'moment'

// Portfolio Images
// Yesref
const yesrefMainImage = require('@/assets/images/portfolio/yesref/yesref.png')
const yesrefSportsOfficialsImage = require('@/assets/images/portfolio/yesref/Yesref-Sports-Officials.png')
const yesrefSportsProvidersImage = require('@/assets/images/portfolio/yesref/YesRef-Sports-Providers.png')
// NGS
const ngsThumbImage = require('@/assets/images/portfolio/ngs/ngs-thumb.png')
const ngsMapImage = require('@/assets/images/portfolio/ngs/ngs-map.png')
const ngsListImage = require('@/assets/images/portfolio/ngs/ngs-list.png')
const ngsGardenImage = require('@/assets/images/portfolio/ngs/ngs-garden.png')
// MSI
const msiLandingImage = require('@/assets/images/portfolio/msi/msi.png')
// Monevo
const monevoLandingImage = require('@/assets/images/portfolio/monevo/monevo.png')
const monevoMoneyImage = require('@/assets/images/portfolio/monevo/money.png')
const monevoUswitchImage = require('@/assets/images/portfolio/monevo/uswitch.png')
// Project Playbook
const projectPlaybookLandingImage = require('@/assets/images/portfolio/project-playbook/playbook-main.png')
// Cargolite
const cargoliteMainImage = require('@/assets/images/portfolio/cargolite/cargolite-main.png')
// Giant Lottos
const giantMainImage = require('@/assets/images/portfolio/giant-lottos/giant-main.png')
const giantCartImage = require('@/assets/images/portfolio/giant-lottos/giant-cart.png')
const giantTicketImage = require('@/assets/images/portfolio/giant-lottos/giant-ticket.png')

// Tech Logos
const activecolabLogo = require('@/assets/images/logos/activecolab.png')
const appcenterLogo = require('@/assets/images/logos/appcenter.png')
const awsLogo = require('@/assets/images/logos/aws.png')
const bitbucketLogo = require('@/assets/images/logos/bitbucket.png')
const capacitorLogo = require('@/assets/images/logos/capacitor.png')
const chatgptLogo = require('@/assets/images/logos/chatgpt.png')
const codeiumLogo = require('@/assets/images/logos/codeium.png')
const digitaloceanLogo = require('@/assets/images/logos/digitalocean.png')
const dockerLogo = require('@/assets/images/logos/docker.png')
const elasticLogo = require('@/assets/images/logos/elastic.png')
const forgeLogo = require('@/assets/images/logos/forge.png')
const lampLogo = require('@/assets/images/logos/lamp.png')
const owaspLogo = require('@/assets/images/logos/owasp.png')
const quasarLogo = require('@/assets/images/logos/quasar.png')
const reactLogo = require('@/assets/images/logos/react.png')
const saloonLogo = require('@/assets/images/logos/saloon.jpeg')
const sassLogo = require('@/assets/images/logos/sass.png')
const symfonyLogo = require('@/assets/images/logos/symfony.png')
const tailwindLogo = require('@/assets/images/logos/tailwind.png')
const testflightLogo = require('@/assets/images/logos/testflight.png')
const typescryptLogo = require('@/assets/images/logos/typescrypt.png')
const vueLogo = require('@/assets/images/logos/vue.png')
const vueXLogo = require('@/assets/images/logos/vue-x.png')

export default {
  data() {
    return {
      experience: [
        {
          startDate: 'Nov, 2021',
          endDate: 'Present',
          title: 'Senior Full-stack Engineer',
          company: 'Totally Communications',
          slug: 'totallyCommunications',
          industry: 'Development House',
          /* eslint-disable max-len */
          description: "At Totally Communications, I've actively led diverse projects, overseeing internal company portals, SaaS, PaaS apps, websites, PWAs, and mobile apps across industries like Sport Refereeing, Property Rental, National Gardens, Health Care, E-commerce, Insurance, and Auditing. My responsibilities included maintaining decoupled projects using Laravel, Vue.js, and React, with mobile apps compiled via Capacitor, ensuring scalability and modularity. Leading end-to-end development, I created mobile apps and web frontend/backend APIs, showcasing proficiency in crafting comprehensive solutions. I played a crucial role in adaptive feature development. Engaging directly with clients, I ensured effective communication and planning, aligning projects with goals and requirements.",
        },
        {
          startDate: 'April, 2019',
          endDate: 'November, 2021',
          title: 'Senior Full-stack Engineer',
          company: 'Quint Group, InQbate',
          slug: 'inqbate',
          industry: 'Development House (Fintech)',
          /* eslint-disable max-len */
          description: "As the Senior Full-stack Engineer at InQbate, I played a crucial role in the comprehensive management of the parent company's (Quint) fintech solutions. Responsibilities included maintaining the core code bases in Laravel, and Vue.js, overseeing the setup, updates, and deployment of over 75 'white label' websites, and leading various aspects of feature development. In overseeing holistic codebase management, I maintained and ensured the integrity of four main code bases using Laravel and Vue.js for fintech solutions. My pivotal role extended to scalable deployment, where I managed the setup, updates, and deployment of over 75 'white label' websites, promoting scalability across diverse financial platforms. Continuously contributing to feature development, I owned the entire development process—from database planning and Laravel (API) to Vue.js frontend and Laravel Backpack admin panel. My commitment to optimal system performance and user experience, I consistently enhanced current systems. As part of comprehensive project ownership, I led new features, encompassing project planning, ticket estimations, and complete application rebuilding for improved efficiency. Emphasizing collaboration, I conducted peer reviews, placing a strong emphasis on performance and code quality through Git and GitHub collaboration.",
        },
        {
          startDate: 'March 2019',
          endDate: 'April 2019',
          title: 'Senior Software Engineer',
          company: 'Billow',
          slug: 'billow',
          industry: 'Development House',
          /* eslint-disable max-len */
          description: "Although my official full-time role at Billow was brief due to business downsizing, I had been a dedicated freelancer with them for four years. Throughout this time, I contributed to a diverse array of projects, ranging from client micro-sites and web apps to Billow's in-house SaaS applications, including their proprietary invoicing product.",
        },
        {
          startDate: 'January, 2017',
          endDate: 'February, 2019',
          title: 'Senior Full-stack Developer',
          company: 'Giant Procurement',
          slug: 'giantProcurement',
          industry: 'Online Gaming',
          /* eslint-disable max-len */
          description: "At Giant Procurement, I played a pivotal role in the re-platforming of their site to Laravel and Vue.js, focusing on feature and re-platform architecture planning, development, and the enhancement of tech services. Were centered around a strategic re-platforming initiative, where I led the planning, prototyping, and architecture of the entire system. This pivotal role involved migrating the site from Symfony and vanilla JavaScript to Laravel and Vue.js, showcasing my expertise in establishing a scalable and efficient online gaming platform. I spearheaded the design and implementation of an API for decoupling front and back-end systems, enabling diverse sources to leverage back-end functionality. The integration of various web services for seamless data export and import was crucial for the platform's functionality and data flow, highlighting my commitment to enhancing user experiences, competitiveness, and security. This included developing external site scrapers to gather relevant data and actively participating in stress testing, penetration testing, and OWASP tests. Additionally, I crafted an Admin Panel in Laravel Nova, providing a user-friendly interface for efficient management and control of users and products on the platform.",
        },
        {
          startDate: 'May 2016',
          endDate: 'December 2016',
          title: 'Code Assassin (Senior Back-end Developer)',
          company: 'Ninja',
          slug: 'ninja',
          industry: 'Development House',
          /* eslint-disable max-len */
          description: 'As the Senior Back-end Developer at Ninja, I held the title of Code Assassin, leading the meticulous development of the back-end (and often the front-end) for a diverse array of client projects, including e-commerce platforms and SaaS applications.',
        },
        {
          startDate: 'February 20008',
          endDate: 'Present',
          title: 'Freelance Web Designer/Developer',
          company: 'Squaricle',
          slug: 'squaricle',
          industry: 'Development House',
          /* eslint-disable max-len */
          description: 'Although I only started working in the industry full time in May 2015 I had been building websites as a freelancer for 7 years previously.',
        },
      ],
      skills: [
        'Self managed',
        'Self motivated',
        'Business oriented',
        'Team player',
        'Efficient',
        'Output driven',
        'Systems architect',
        'Problem solver',
        'Full development lifecycle',
        'Planning',
        'Architecture',
        'Database Design',
        'Front-end & Back-end (php)',
        'Server Setup',
        'Deployments',
        'Peer Reviews',
        'API integrations',
        'OAuth',
        'Payment Gateway integrations',
        'Team Leading',
        'Guide Juniors',
      ],
      technologies: [
        {
          name: 'Active Collab',
          level: 'proficient',
          slug: 'activecollab',
          link: 'https://activecollab.com',
          logo: activecolabLogo,
          startDate: '2017-01-01',
          endDate: '2019-02-28',
          type: 'static',
        },
        {
          name: 'App Center',
          level: 'novice',
          slug: 'appCenter',
          link: 'https://appcenter.ms/',
          logo: appcenterLogo,
          startDate: '2019-03-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'AWS',
          level: 'novice',
          slug: 'aws',
          link: 'https://aws.amazon.com/',
          logo: awsLogo,
          startDate: '2019-03-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Bitbucket',
          level: 'moderate',
          slug: 'bitbucket',
          link: 'https://bitbucket.org/',
          logo: bitbucketLogo,
          startDate: '2017-01-01',
          endDate: null,
          experience: 3,
          type: 'static',
        },
        {
          name: 'Bootstrap',
          level: 'advanced',
          slug: 'bootstrap',
          link: 'https://getbootstrap.com/',
          logo: 'https://cdn.iconscout.com/icon/free/png-256/free-bootstrap-6-1175203.png?f=webp',
          startDate: '2010-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Capacitor',
          level: 'moderate',
          slug: 'capacitor',
          link: 'https://capacitorjs.com/',
          logo: capacitorLogo,
          startDate: '2021-11-01',
          endDate: '2023-12-31',
          type: 'dynamic',
        },
        {
          name: 'ChatGPT',
          level: 'moderate',
          slug: 'chatGPT',
          link: 'https://chat.openai.com',
          logo: chatgptLogo,
          startDate: '2023-06-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Codeium',
          level: 'moderate',
          slug: 'codeium',
          link: 'https://codeium.com/',
          logo: codeiumLogo,
          startDate: '2023-06-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Composer',
          level: 'advanced',
          slug: 'composer',
          link: 'https://getcomposer.org',
          logo: 'https://getcomposer.org/img/logo-composer-transparent2.png',
          startDate: '2011-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Digital Ocean',
          level: 'moderate',
          slug: 'digitalOcean',
          link: 'https://www.digitalocean.com/',
          logo: digitaloceanLogo,
          startDate: '2017-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Docker',
          level: 'moderate',
          slug: 'docker',
          link: 'https://www.docker.com/',
          logo: dockerLogo,
          startDate: '2019-03-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Elastic Search',
          level: 'moderate',
          slug: 'elasticSearch',
          link: 'https://www.elastic.co',
          logo: elasticLogo,
          startDate: '2021-11-01',
          endDate: '2023-12-31',
          type: 'dynamic',
        },
        {
          name: 'git',
          level: 'moderate',
          slug: 'git',
          link: 'https://git-scm.com/',
          logo: 'https://git-scm.com/images/logos/downloads/Git-Logo-2Color.png',
          startDate: '2016-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Javascript',
          level: 'advanced',
          slug: 'javascript',
          link: 'https://www.javascript.com/',
          logo: 'https://quiksite.com/wp-content/uploads/2016/09/Javascript-Square.png',
          startDate: '2011-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'jQuery',
          level: 'advanced',
          slug: 'jquery',
          link: 'https://jquery.com/',
          logo: 'https://www.logolynx.com/images/logolynx/2c/2c196cd4d023478f6306dfca28f6ff5f.png',
          startDate: '2011-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'LAMP stack',
          level: 'advanced',
          slug: 'lampStack',
          link: 'https://en.wikipedia.org/wiki/LAMP_(software_bundle)',
          logo: lampLogo,
          startDate: '2015-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'laravel',
          level: 'advanced',
          slug: 'laravel',
          link: 'https://laravel.com/',
          logo: 'https://2.bp.blogspot.com/-bGTsFydT5d8/WM-Gs0K0FTI/AAAAAAAAFpM/AGJ-DWijk785bQjk9FF99PpcySIrtWHIgCPcB/s1600/laravel_logo.png',
          startDate: '2013-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Laravel Backpack',
          level: 'moderate',
          slug: 'laravelBackpack',
          link: 'https://backpackforlaravel.com/',
          logo: 'https://backpackforlaravel.com/presentation/img/backpack/logos/backpack_logo.svg',
          startDate: '2019-01-01',
          endDate: '2021-10-01',
          type: 'static',
        },
        {
          name: 'Laravel Forge',
          level: 'moderate',
          slug: 'laravelForge',
          link: 'https://forge.laravel.com/',
          logo: forgeLogo,
          startDate: '2019-01-01',
          endDate: '2021-10-01',
          type: 'static',
        },
        {
          name: 'laravel-lumen',
          level: 'advanced',
          slug: 'laravelLumen',
          link: 'https://lumen.laravel.com/',
          logo: 'https://cdn.freebiesupply.com/logos/large/2x/lumen-1-logo-black-and-white.png',
          startDate: '2018-01-01',
          endDate: '2019-01-01',
          type: 'static',
        },
        {
          name: 'laravel-nova',
          level: 'proficient',
          slug: 'laravelNova',
          link: 'https://nova.laravel.com/',
          logo: 'https://cdn-images-1.medium.com/max/2000/1*KMK4cOVWwcOhG651DEgGrA.png',
          startDate: '2018-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'mysql',
          level: 'advanced',
          slug: 'mysql',
          link: 'http://www.mysql.com/',
          logo: 'https://pngimg.com/uploads/mysql/mysql_PNG32.png',
          startDate: '2007-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'npm',
          level: 'advanced',
          slug: 'npm',
          link: 'https://www.npmjs.com/',
          logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Npm-logo.svg/320px-Npm-logo.svg.png',
          startDate: '2014-06-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'node.js',
          level: 'intermediate',
          slug: 'nodejs',
          link: 'https://nodejs.org/',
          logo: 'http://pluspng.com/img-png/nodejs-logo-vector-png-node-js-logo-nodejs-javascript-source-code-960.png',
          startDate: '2016-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Orion',
          level: 'advanced',
          slug: 'orion',
          link: 'https://tailflow.github.io/laravel-orion-docs/',
          logo: 'https://tailflow.github.io/laravel-orion-docs/logo.png',
          startDate: '2019-01-01',
          endDate: '2023-12-31',
          type: 'static',
        },
        {
          name: 'OWASP',
          level: 'novice',
          slug: 'owasp',
          link: 'https://owasp.org/',
          logo: owaspLogo,
          startDate: '2017-01-01',
          endDate: '2019-01-01',
          type: 'static',
        },
        {
          name: 'PHP',
          level: 'advanced',
          slug: 'php',
          link: 'http://php.net/',
          logo: 'https://espeo.eu/wp-content/uploads/2016/07/php-logo-92bc522f00051c3519e408391b965d0e.png',
          startDate: '2007-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Quasar',
          level: 'advanced',
          slug: 'quasar',
          link: 'https://quasar.dev/',
          logo: quasarLogo,
          startDate: '2021-11-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'React',
          level: 'moderate',
          slug: 'react',
          link: 'https://react.dev/',
          logo: reactLogo,
          startDate: '2021-11-01',
          endDate: '2022-06-01',
          type: 'static',
        },
        {
          name: 'Saloon',
          level: 'moderate',
          slug: 'aloon',
          link: 'https://docs.saloon.dev/',
          logo: saloonLogo,
          startDate: '2023-11-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'SASS',
          level: 'advanced',
          slug: 'sass',
          link: 'https://sass-lang.com/',
          logo: sassLogo,
          startDate: '2015-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'sequelpro',
          level: 'advanced',
          slug: 'sequelpro',
          link: 'http://www.sequelpro.com/',
          logo: 'https://cdn.dribbble.com/users/671/screenshots/821803/sequelpro_2x.png',
          startDate: '2015-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'socket.io',
          level: 'intermediate',
          slug: 'socketio',
          link: 'http://socket.io/',
          logo: 'https://cdn.freebiesupply.com/logos/large/2x/socket-io-logo-png-transparent.png',
          startDate: '2016-01-01',
          endDate: '2017-01-01',
          type: 'static',
        },
        {
          name: 'slack',
          level: 'advanced',
          slug: 'slack',
          link: 'https://slack.com',
          logo: 'https://marketing-assets.pivotaltracker.com/marketing_assets/integrations/slack-icon-d3f6b7c14101b18ed0cb6236fb94ad1df8f8a7849c62bc8c966394f467f2f16a.png',
          startDate: '2015-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Symfony',
          level: 'moderate',
          slug: 'symfony',
          link: 'https://symfony.com/',
          logo: symfonyLogo,
          startDate: '2019-01-01',
          endDate: '2020-02-01',
          type: 'static',
        },
        {
          name: 'Tailwind',
          level: 'moderate',
          slug: 'tailwind',
          link: 'https://tailwindcss.com/',
          logo: tailwindLogo,
          startDate: '2019-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'TestFlight',
          level: 'novice',
          slug: 'testFlight',
          link: 'https://testflight.apple.com/',
          logo: testflightLogo,
          startDate: '2019-01-01',
          endDate: '2022-12-31',
          type: 'static',
        },
        {
          name: 'Trello',
          level: 'proficient',
          slug: 'trello',
          link: 'https://trello.com',
          logo: 'https://d2k1ftgv7pobq7.cloudfront.net/meta/u/res/images/brand-assets/Logos/0099ec3754bf473d2bbf317204ab6fea/trello-logo-blue.png',
          startDate: '2016-01-01',
          endDate: '2019-01-01',
          type: 'static',
        },
        {
          name: 'Typescript',
          level: 'advanced',
          slug: 'typescript',
          link: 'https://www.typescriptlang.org/',
          logo: typescryptLogo,
          startDate: '2019-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Vue-api-query',
          level: 'advanced',
          slug: 'vueApiQuery',
          link: 'https://robsontenorio.github.io/vue-api-query/',
          logo: vueLogo,
          startDate: '2021-11-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Vue-router',
          level: 'advanced',
          slug: 'vueRouter',
          link: 'https://router.vuejs.org/',
          logo: vueLogo,
          startDate: '2017-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Vue-x',
          level: 'advanced',
          slug: 'vueX',
          link: 'https://vuex.vuejs.org/',
          logo: vueXLogo,
          startDate: '2017-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'vue.js',
          level: 'moderate',
          slug: 'vuejs',
          link: 'http://vuejs.org/',
          logo: vueLogo,
          startDate: '2015-01-01',
          endDate: null,
          type: 'dynamic',
        },
        {
          name: 'Yarn',
          level: 'advanced',
          slug: 'yarn',
          link: 'https://yarnpkg.com/',
          logo: 'https://iconape.com/wp-content/png_logo_vector/yarn-logo.png',
          startDate: '2017-01-01',
          endDate: null,
          type: 'dynamic',
        },
      ],
      projects: [
        {
          name: 'Yesref',
          url: 'https://www.yesref.com',
          tech: [
            'php',
            'laravel',
            'mysql',
            'vuejs',
            'vueRouter',
            'vueApiQuery',
            'vueX',
            'orion',
            'capacitor',
            'quasar',
          ],
          features: [
            'Decoupled SPA, Admin & API',
            'Calendar',
            'Payment Gateway',
            'Bookings',
            'Mobile App & Browser',
          ],
          slug: 'yesref',
          company: 'totallyCommunications',
          industry: 'sports, referee services',
          thumb: yesrefMainImage,
          images: [
            yesrefMainImage,
            yesrefSportsOfficialsImage,
            yesrefSportsProvidersImage,
          ],
          /* eslint-disable max-len */
          description: 'Though existing for a few years, I played a pivotal role in developing Yesref, an innovative app transforming referee recruitment for sports events. The platform empowers sports providers to seamlessly discover and hire referees, from novice to FIFA-level in football. My contributions involved crafting features for payment processing and refining the system for managing referee availability through an intuitive calendar system.',
          /* eslint-disable max-len */
          keyContributions: "I created an advanced calendar system for referees, allowing them to manage availability and block out times they couldn't officiate. Emphasizing efficient database usage, the system stored essential information for optimized performance. It accommodated repeatable events like regular unavailability on specific days and times, handling the vast data generated by thousands of sports providers, tens of thousands of referees, and hundreds of weekly games.<br/><br/>This pivotal calendar system set the stage for a groundbreaking feature benefiting sporting event providers. It facilitated searches for available referees using geospatial data, considering factors like referee and event locations, date and times, qualifications, and other filters. Optimizing queries to prevent slowdowns, especially with extensive relational data, involved strategic design to avoid potential n+1 issues. Despite Laravel's query builder efficiency, a blend of raw queries and joins was essential. The system not only ranked referees based on criteria met but also presented alternative referees who, while available, didn't meet specific non-essential criteria.",
        },
        {
          name: 'National Garden Scheme [NGS] (find a garden)',
          url: 'https://findagarden.ngs.org.uk',
          tech: [
            'php',
            'laravel',
            'mysql',
            'vuejs',
            'vueRouter',
            'vueX',
            'capacitor',
          ],
          features: [
            'Decoupled SPA, Admin & API',
            'Custom Google Maps Integration',
            'Google Places Integration',
            'Mobile App & Browser',
          ],
          slug: 'findAGarden',
          company: 'totallyCommunications',
          industry: 'NGO, location listing',
          thumb: ngsThumbImage,
          images: [
            ngsMapImage,
            ngsListImage,
            ngsGardenImage,
          ],
          /* eslint-disable max-len */
          description: 'Find a Garden is a comprehensive platform, granting exclusive access to over 3,500 exceptional private gardens throughout the UK. Beyond the joy of exploration, it serves a dual purpose by actively supporting nursing and health charities, generating around £4.5 million annually. My role in this project involved developing both a website and a mobile app, intricately connected through a decoupled API.',
          /* eslint-disable max-len */
          keyContributions: 'As the lead developer on this new project, I took the helm in architecting the Laravel API, spearheading the development of the Vue frontend, and constructing the Capacitor mobile apps. A standout feature in my contributions was the implementation of a dynamic Google Map showcasing thousands of gardens through geolocation-based pins. This interactive map allowed users to seamlessly filter and navigate through garden locations. Leveraging geospatial ElasticSearch, the platform efficiently filtered gardens based on the visible map space, enhancing the user experience with spatially relevant results.',
        },
        {
          name: 'Marie Stropes International [MSI]  (family planning questionnaire)',
          url: 'https://global.choicecounsellor.msichoices.org/',
          tech: [
            'php',
            'laravel',
            'mysql',
            'vuejs',
            'vueRouter',
            'vueX',
            'capacitor',
          ],
          features: [
            'Mobile app development',
            'Decoupled API architecture',
            'Multi-language support',
            'Offline data storage',
            'Patient assessment via questionnaire',
          ],
          slug: 'msi',
          company: 'totallyCommunications',
          industry: 'NGO, Healthcare services',
          thumb: msiLandingImage,
          images: [
            msiLandingImage,
          ],
          /* eslint-disable max-len */
          description: 'Marie Stopes International (MSI) Family Planning Questionnaire is a crucial service designed to support family and pregnancy planning, specifically focusing on female health. The platform serves as a valuable tool for medical staff worldwide, especially in underdeveloped countries. The project includes a website, a mobile app, a decoupled API, and an admin portal, ensuring accessibility and efficient management of the questionnaire service.<br>This project reflects my commitment to leveraging technology for social impact, providing medical professionals worldwide with a comprehensive tool to enhance family planning services and female health support.',
          /* eslint-disable max-len */
          keyContributions: 'In my role as Lead Developer, I spearheaded the development of a sophisticated multi-language app designed for healthcare professionals to conduct patient assessments in areas with limited network coverage. The app facilitates personalized advice on contraception or pregnancy planning based on individual patient histories gathered through a questionnaire. Patient data is securely stored locally on the device and seamlessly uploaded to servers for processing once network connectivity is restored.',
        },
        {
          name: 'Monevo',
          url: 'https://monevo.com',
          tech: [
            'php',
            'laravel',
            'laravelBackpack',
            'laravelForge',
            'mysql',
            'testFlight',
            'vuejs',
            'vueRouter',
            'vueX',
            'aws',
          ],
          features: [
            'Multi-service credit comparison',
            'Global geographical coverage',
            'User-centric financial resource',
            'Tailored product options',
            'Scalable deployment',
            'System performance enhancement',
            'White labelled',
            'Mobile App & Browser',
          ],
          slug: 'monevo',
          company: 'inqbate',
          industry: 'fintech, financial services',
          thumb: monevoLandingImage,
          images: [
            monevoLandingImage,
            monevoMoneyImage,
            monevoUswitchImage,
          ],
          /* eslint-disable max-len */
          description: 'Monevo is a dynamic website designed for comparing loans and credit offerings from various financial services across the UK, USA, Australia, and other parts of Europe. The platform serves as a comprehensive resource for users seeking to evaluate and select the most suitable loan or credit option tailored to their needs.',
          /* eslint-disable max-len */
          keyContributions: 'In overseeing holistic codebase management, I maintained and ensured the integrity of four main code bases using Laravel and Vue.js for fintech solutions. My pivotal role extended to scalable deployment, where I managed the setup, updates, and deployment of over 75 "white label" websites, promoting scalability across diverse financial platforms.<br> Continuously contributing to feature development, I owned the entire development process—from database planning and Laravel (API) to Vue.js frontend and  Laravel Backpack admin panel. My commitment to optimal system performance and user experience, I consistently enhanced current systems. As part of comprehensive project ownership, I led new features, encompassing project planning, ticket estimations, and complete application rebuilding for improved efficiency. Emphasizing collaboration, I conducted peer reviews, placing a strong emphasis on performance and code quality through Git and GitHub.',
        },
        {
          name: 'Project Playbook',
          url: 'https://project-playbook.com',
          tech: [
            'php',
            'laravel',
            'mysql',
            'vuejs',
            'vueRouter',
            'vueX',
          ],
          slug: 'projectPlaybook',
          company: 'billow',
          industry: 'Landing page',
          thumb: projectPlaybookLandingImage,
          images: [
            projectPlaybookLandingImage,
          ],
          /* eslint-disable max-len */
          description: "Project Playbook served as a dedicated microsite strategically designed to promote a massive international competition hosted by Playbook Hub. This specialized microsite played a crucial role in creating awareness, engaging participants, and providing essential information related to the competition.<br>This project showcases my ability to design and implement targeted microsites to support and enhance the promotional efforts of larger initiatives, contributing to the overall success of Playbook Hub's international competition.",
          /* eslint-disable max-len */
          keyContributions: '',
        },
        {
          name: 'Playbook Hub',
          url: 'https://playbookhub.com',
          tech: [
            'php',
            'laravel',
            'mysql',
            'vuejs',
            'vueRouter',
            'vueX',
          ],
          slug: 'playbookHub',
          company: 'billow',
          industry: 'service listings',
          thumb: projectPlaybookLandingImage,
          images: [
            projectPlaybookLandingImage,
          ],
          /* eslint-disable max-len */
          description: 'Playbook Hub is a comprehensive web platform tailored for artists to showcase their work and connect with potential opportunities such as events and shows. The application adopts a decoupled architecture, featuring a Laravel API, a Vue.js frontend, a separate Vue.js admin panel, a dedicated mobile app, and multiple microsites for competitions and specialized showcases.<br>This project underscores my proficiency in creating versatile and interconnected web solutions, utilizing Laravel, Vue.js, and API integrations to deliver a seamless experience for artists and event organizers on the Playbook Hub platform.',
          /* eslint-disable max-len */
          keyContributions: 'As the Lead Developer on this project, I designed and implemented the SPA\'s catalog and booking system, developed the administrative portal for artist, client, and event management, integrated payment gateways, facilitated invoicing and payments, and enhanced user profile areas.',
        },
        {
          name: 'Cargolite',
          url: 'Internal Web Application',
          tech: [
            'php',
            'laravel',
            'mysql',
            'vuejs',
            'vueRouter',
            'vueX',
          ],
          features: [
            'Decoupled SPA, Admin & API',
            'Decoupled architecture',
            'Laravel API',
            'Vue.js frontend',
            'Separate Vue.js admin panel',
            'Dedicated mobile app',
            'Multiple microsites',
            'Payment Gateway Integration',
          ],
          slug: 'cargolite',
          company: 'billow',
          industry: 'Shipping, logistics',
          thumb: cargoliteMainImage,
          images: [
            cargoliteMainImage,
          ],
          /* eslint-disable max-len */
          description: 'Developed during my tenure at Billow, the Cargolite Internal Web Application serves as a vital tool for managing business processes at Megafreight, a prominent freight and shipping company. This internal application streamlines and enhances various aspects of their operations, with a focus on efficiently managing bookings, shipping processes, and comprehensive rate and tariff information.<br>This project highlights my ability to create tailored solutions that meet specific business needs, utilizing web technologies to optimize and centralize essential processes within the freight and shipping industry.',
          /* eslint-disable max-len */
          keyContributions: '',
        },
        {
          name: 'Giant Lottos',
          url: 'https://giantlottos.com',
          tech: [
            'php',
            'laravel',
            'laravel nova',
            'mysql',
            'vuejs',
          ],
          slug: 'giantLottos',
          company: 'giantProcurement',
          industry: 'Online Gaming, ecommerce',
          thumb: giantMainImage,
          images: [
            giantMainImage,
            giantCartImage,
            giantTicketImage,
          ],
          /* eslint-disable max-len */
          description: 'Giant Lottos Ecommerce Site stands as a prominent player in the online gaming industry. Specializing in the sale of lottery tickets for major lotteries worldwide, the platform is designed with a completely decoupled architecture, separating the frontend and backend for enhanced flexibility and scalability.',
          /* eslint-disable max-len */
          keyContributions: "Were centered around a strategic re-platforming initiative, where I led the planning, prototyping, and architecture of the entire system. This pivotal role involved migrating the site from Symfony and vanilla JavaScript to Laravel and Vue.js, showcasing my expertise in establishing a scalable and efficient online gaming platform.<br>I spearheaded the design and implementation of an API for decoupling front and back-end systems, enabling diverse sources to leverage back-end functionality. The integration of various web services for seamless data export and import was crucial for the platform's functionality and data flow, highlighting my commitment to enhancing user experiences, competitiveness, and security. This included developing external site scrapers to gather relevant data and actively participating in stress testing, penetration testing, and OWASP tests. Additionally, I crafted an Admin Panel in Laravel Nova, providing a user-friendly interface for efficient management and control of users and products on the platform.",
        },
      ],
    }
  },

  methods: {
    techList() {
      return this.technologies
        .map((tech) => ({
          ...tech,
          techDuration: this.techDuration(tech),
          techExperience: this.techExperience(tech),
          lastUsed: this.lastUsed(tech),
        }))
        .sort((a, b) => {
          return b.techDuration - a.techDuration
        })
        .sort((a, b) => {
          let sorting = 1
          if (a.lastUsed === 'current' && b.lastUsed !== 'current') {
            sorting = -1; // Move 'current' tech before others
          }
          return sorting
        })
    },
    techDuration(tech) {
      if (tech.static && tech.experience) {
        return tech.experience
      }
      const start = moment(tech.startDate, 'YYYY-MM-DD')
      const end = tech.endDate
        ? moment(tech.endDate, 'YYYY-MM-DD')
        : moment()

      return end.diff(start, 'years')
    },

    techExperience(tech) {
      const duration = this.techDuration(tech)
      const experience = duration > 1 ? ' years' : ' year'
      if (duration < 1) {
        return 'a few months'
      }
      return duration + experience
    },
    lastUsed(tech) {
      return tech.endDate
        ? moment(tech.endDate, 'YYYY-MM-DD').fromNow()
        : 'current'
    },
    companyBySlug(slug) {
      return this.experience.find((company) => company.slug === slug)
    },
    techBySlug(slug) {
      return this.technologies.find((technology) => technology.slug === slug)
    },
  },
}
