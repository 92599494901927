<template>
  <footer id="footer" ref="footer">
    <video-background
      class="hero-background"
      :src="contactVideo"
      v-parallax="0.5"
      absY>
    </video-background>
    <div class="overlay-darker"></div>
    <div class="contact-content container-fluid fh d-flex justify-content-center align-items-center">
      <div class="content-bottom">
        <div class="footer-text-container dark-bg">
          <h1 class="outline animate__animated animate__slideInUp">CONTACT ME</h1>
          <div class="fs-3 bright animate__animated animate__slideInLeft">Call me: <a href="tel:+27826189060"> +2782 618 9060</a></div>
          <div class="fs-3 bright animate__animated animate__slideInRight">Mail me: <a href="mailto:dev@justinsmith.co.za">dev@justinsmith.co.za</a></div>
        </div><!-- / footer-text-container -->
      </div><!-- / footer-content -->
    </div>
  </footer>

</template>

<script>
import HelperMixin from '@/services/HelperMixin'

const contactVideo = require('@/assets/videos/CouchFrontMain.mp4')

export default {
  name: 'ContactMe',

  mixins: [HelperMixin],

  data() {
    return {
      contactVideo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
#footer {
  position: relative;
}
.hero-background {
  z-index: 1;
  // position: relative;
  // overflow: hidden;
  height: calc(100vh);
}
.overlay-darker {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    height: calc(100vh);
    background: rgba(22, 24, 29, 0.75);
}
.contact-content {
  position: absolute;
  z-index: 3;
  top: 0;
  .footer-text-container {
    color: white;
    text-align: center;
  }
}
</style>
