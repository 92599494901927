<template>
  <header id="header" ref="header">
    <video-background
      class="hero-background"
      :src="mainVideo"
      v-parallax="0.5">
    </video-background>
    <div class="overlay-darker"></div>
    <div class="header-content container-fluid fh d-flex flex-column justify-content-end">
      <div class="content-bottom">
        <div class="header-text-container dark-bg">
          <h1 class="outline animate__animated animate__slideInLeft">JUSTIN SMITH</h1>
          <div class="fs-3 bright animate__animated animate__slideInUp">SENIOR FULL-STACK ENGINEER</div>
          <div class="header-bottom-wrapper">
            <div class="header-down-icon-wrapper">
              <div class="header-down-icon animate__animated animate__pulse animate__slow animate__infinite">
                <a
                  @click="scrollToSection('#profile-container')">
                  <font-awesome-icon :icon="['fas',  'arrow-down']" />
                </a>
              </div>
              <div class="d-inline-block va-middle ml-10 fs-18 fw-light opc-75">Scroll to see more</div>
            </div>
            <ul class="list-inline list-featured header-nav-wrapper animate__animated animate__slideInRight">
              <li
                v-for="(item, itemIndex) in navItems"
                :key="itemIndex">
                <a @click="scrollToSection(item.element)">{{ item.title }}</a></li>
            </ul>
          </div>
        </div><!-- / header-text-container -->
      </div><!-- / header-content -->
      <div class="header-nav tablet-lg-hidden">
      </div><!-- / header-nav -->
    </div>
  </header>

</template>

<script>
import HelperMixin from '@/services/HelperMixin'

const mainVideo = require('@/assets/videos/CouchSideMain-4.mp4')

export default {
  name: 'HeadHero',

  mixins: [HelperMixin],

  data() {
    return {
      mainVideo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variables.scss';
#header {
  height: calc(100vh);
  .container-fluid {
    top: 0;
    &.fh {
      height: calc(100vh);
    }
  }
  .hero-background {
    z-index: 1;
    position: relative;
    overflow: hidden;
    height: calc(100vh);
  }
  .overlay-darker {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      height: calc(100vh);
      background: rgba(22, 24, 29, 0.75);
  }
  .header-content {
    position: absolute;
    z-index: 3;
    top: 0;
    .header-text-container {
      color: white;
      h1 {
        line-height: 1;
        margin-bottom: 0;
      }
      .fs-3 {
        line-height: 1;
        margin-bottom: 0;
        font-weight: 100;
      }
      .header-bottom-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        .header-down-icon-wrapper {
          display: flex;
          align-items: center;
          color: $color-grey-main;
          .header-down-icon {
            border: 2px solid $color-grey-main;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin-right: 15px;
            cursor: pointer;
              &:hover {
                color: $color-white-main;
                border-color: $color-white-main;
              }
          }
        }
        .header-nav-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          @media only screen and (max-width: 767px) {
            display: none;
          }

          li {
            color: $color-grey-main;
            margin-left: 20px;
            a{
              cursor: pointer;
              &:hover {
                color: $color-white-main;
              }
            }
          }
        }
      }
    }
  }
}
</style>
