<template>
  <div id="app">
    <NavBar/>
    <HeadHero/>
    <Profile/>
    <ContactLink/>
    <Experience/>
    <ContactLink/>
    <Portfolio/>
    <ContactLink/>
    <Technologies/>
    <Contact/>
    <!-- <img alt="Vue logo" src="@/assets/images/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App2"/> -->
  </div>
</template>

<script>
import NavBar from '@/components/nav/NavBar'
import HeadHero from '@/components/head/Hero'
import Profile from '@/components/sections/profile/Profile'
import ContactLink from '@/components/nav/ContactLink'
import Portfolio from '@/components/sections/portfolio/Portfolio'
import Technologies from '@/components/sections/technologies/Technologies'
import Experience from '@/components/sections/experience/Experience'
import Contact from '@/components/sections/contact/Contact'

export default {
  name: 'App',

  components: {
    NavBar,
    HeadHero,
    Profile,
    ContactLink,
    Experience,
    Portfolio,
    Technologies,
    Contact,
  },

  methods: {
    // visibilityChanged (isVisible, entry) {
    //   console.log(isVisible)
    //   // this.isVisible = isVisible
    //   console.log(entry.target.id)
    //   this.currentlyVisible = entry.target.id
    // }
  },
}
</script>

<style>
#app {
  height: 100%;
}
</style>
